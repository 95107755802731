var domain = (location.host.match(/([^.]+)\.\w{2,3}(?:\.\w{2})?$/) || [])[1]
const full = window.location.host;
const part = full.split('.');
let sub = part[0];
let subDomain = part[1];
let dom = part[0];

let parts ='http://15.206.52.226:8882/api/en/';
if (sub === 'localhost:4200' || sub === 'rewardkartshop' || !sub || sub === 'us-constient' || sub === '52') {
    sub = 'voucherkart';
}

if (domain === 'rewardkartshop') {
    parts = 'https://rewardkartapi.in:8060/api/en/';
}
else if(domain === "gift1to1" && subDomain !== "gift1to1"){
    parts = 'https://b2capi.voucherkart.co.in:8066/api/en/';
    sub = "voucherkart";
}
else if (domain === 'us-constient') {
    parts = 'https://api.constient.us:8081/api/en/';
}

else if(dom === "ae" && subDomain === "gift1to1"){
    parts = 'https://ae.gift1to1.com:8066/api/en/';
    sub = "voucherkart";
}
parts = 'https://ae.gift1to1.com:8066/api/en/';
sub = "voucherkart";

// window.location.host is subdomain.domain.com

// for Live domain
let thnewCustomerApiUrl = parts + "customers/subdomain?domain="+ sub ;

$.ajax({
    url: thnewCustomerApiUrl,
    type: 'GET',
    dataType: 'json',
    async: false,
    success: function(result) {
        let baseURL = '';
        if(result.Subdomain !== '') {
            sessionStorage.setItem('baseURL', JSON.stringify(result.Subdomain));
            baseURL = result.Subdomain + '-';
        } else {
            if(sessionStorage.getItem('baseURL') !== undefined && sessionStorage.getItem('baseURL') !== null) {
                sessionStorage.removeItem('baseURL');
            }
        }

        let lang_code = '';
        const userSettings = JSON.parse(sessionStorage.getItem('userSettings'));
        if (userSettings !== null && userSettings !== undefined && userSettings !== '') {
            lang_code = userSettings.LanguageCode;
        } else {
            lang_code = 'en';
        }
        let user_lang_code = '';
        let customerApiUrl = '';
        if (sessionStorage.getItem("themecolors") !== null && sessionStorage.getItem("themecolors") !== undefined && sessionStorage.getItem("themecolors") !== '') {
         
            let webshopDetail = JSON.parse(sessionStorage.getItem('themecolors'));
            // var webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
            less.modifyVars({ '@color-base': webshopDetail.Main, '@label-color': webshopDetail.Lable, '@alert-color': webshopDetail.Alert });
      
        } else {
            const loggedInData = sessionStorage.getItem('loggedInData') ? JSON.parse(sessionStorage.getItem('loggedInData')) : null;
            if (loggedInData != null && loggedInData !== undefined) {
                user_lang_code = loggedInData.UserLanguageCode;
                customerApiUrl = parts + "customers?name=" + sub + "&languagecode=" + lang_code + "&userLanguageCode=" + user_lang_code + "&userID=" + loggedInData.UserID;
            } else if (userSettings !== null && userSettings !== undefined && userSettings !== '') {
                user_lang_code = userSettings.LanguageCode;
                customerApiUrl = parts + "customers?name=" + sub + "&languagecode=" + lang_code + "&userLanguageCode=" + user_lang_code;
            } else {
                customerApiUrl = parts + "customers?name=" + sub + "&languagecode=" + lang_code;
            }
            $.ajax({
                url: customerApiUrl,
                global: false,
                type: 'GET',
                data: {},
                async: false,
                success: function(result) {
                    if (result.isWebShopActive) {
                        //            setTimeout(function () {
                        sessionStorage.setItem("webshopDetail", JSON.stringify(result));
                        sessionStorage.setItem("themecolors", JSON.stringify(result.Themes[0]));
                        less.modifyVars({ '@color-base': result.Themes[0].Main, '@label-color': result.Themes[0].Lable, '@alert-color': result.Themes[0].Alert });
                        
                    }
                }
            });
        }


        const loggedInData = sessionStorage.getItem('loggedInData') ? JSON.parse(sessionStorage.getItem('loggedInData')) : null;
        if (loggedInData != null && loggedInData !== undefined) {
            user_lang_code = loggedInData.UserLanguageCode;
            customerApiUrl = parts + "customers?name=" + sub + "&languagecode=" + lang_code + "&userLanguageCode=" + user_lang_code + "&userID=" + loggedInData.UserID;
        } else if (userSettings !== null && userSettings !== undefined && userSettings !== '') {
            user_lang_code = userSettings.LanguageCode;
            customerApiUrl = parts + "customers?name=" + sub + "&languagecode=" + lang_code + "&userLanguageCode=" + user_lang_code;
        } else {
            customerApiUrl = parts + "customers?name=" + sub + "&languagecode=" + lang_code;
        }
        $.ajax({
            url: customerApiUrl,
            global: false,
            type: 'GET',
            data: {},
            async: false,
            success: function(result) {
                if (result.isWebShopActive) {
                    sessionStorage.setItem("webshopDetail", JSON.stringify(result));
                    sessionStorage.setItem("themecolors", JSON.stringify(result.Themes[0]));
                    less.modifyVars({ '@color-base': result.Themes[0].Main, '@label-color': result.Themes[0].Lable, '@alert-color': result.Themes[0].Alert });
                }
            }
        });
    }
});







// let lang_code = '';
// const userSettings = JSON.parse(sessionStorage.getItem('userSettings'));
// if (userSettings !== null && userSettings !== undefined && userSettings !== '') {
//     lang_code = userSettings.LanguageCode;
// } else {
//     lang_code = 'en';
// }
// let user_lang_code = '';
// let customerApiUrl = '';
// if (sessionStorage.getItem("themecolors") !== null && sessionStorage.getItem("themecolors") !== undefined && sessionStorage.getItem("themecolors") !== '') {
//     console.log("inside Session Already Exist");
//     console.log(JSON.parse(sessionStorage.getItem('themecolors')));
//     let webshopDetail = JSON.parse(sessionStorage.getItem('themecolors'));
//     // var webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
//     less.modifyVars({ '@color-base': webshopDetail.Main, '@label-color': webshopDetail.Lable, '@alert-color': webshopDetail.Alert });
//     console.log(webshopDetail.Main)
// } else {
//     const loggedInData = sessionStorage.getItem('loggedInData') ? JSON.parse(sessionStorage.getItem('loggedInData')) : null;
//     if (loggedInData != null && loggedInData !== undefined) {
//         user_lang_code = loggedInData.UserLanguageCode;
//         customerApiUrl = parts + "/api/encustomers?name=" + sub + "&languagecode=" + lang_code + "&userLanguageCode=" + user_lang_code + "&userID=" + loggedInData.UserID;
//     } else if (userSettings !== null && userSettings !== undefined && userSettings !== '') {
//         user_lang_code = userSettings.LanguageCode;
//         customerApiUrl = parts + "/api/encustomers?name=" + sub + "&languagecode=" + lang_code + "&userLanguageCode=" + user_lang_code;
//     } else {
//         customerApiUrl = parts + "/api/encustomers?name=" + sub + "&languagecode=" + lang_code;
//     }
//     $.ajax({
//         url: customerApiUrl,
//         global: false,
//         type: 'GET',
//         data: {},
//         async: false,
//         success: function(result) {
//             if (result.isWebShopActive) {
//                 //            setTimeout(function () {
//                 sessionStorage.setItem("webshopDetail", JSON.stringify(result));
//                 sessionStorage.setItem("themecolors", JSON.stringify(result.Themes[0]));
//                 less.modifyVars({ '@color-base': result.Themes[0].Main, '@label-color': result.Themes[0].Lable, '@alert-color': result.Themes[0].Alert });
//                 // if (sessionStorage.getItem("themeReApplyStatus") === null || sessionStorage.getItem("themeReApplyStatus") === undefined) {
//                 //     console.log("inside First Time Call");
//                 //     console.log(result.Themes[0])
//                 //     less.modifyVars({ '@color-base': result.Themes[0].Main, '@label-color': result.Themes[0].Lable, '@alert-color': webshopDetail.Themes[0].Alert });
//                 //     sessionStorage.setItem("themeReApplyStatus", false);
//                 // }
//                 //          }, 200);
//             }
//         }
//     });
// }


// const loggedInData = sessionStorage.getItem('loggedInData') ? JSON.parse(sessionStorage.getItem('loggedInData')) : null;
// if (loggedInData != null && loggedInData !== undefined) {
//     user_lang_code = loggedInData.UserLanguageCode;
//     customerApiUrl = parts + "/api/encustomers?name=" + sub + "&languagecode=" + lang_code + "&userLanguageCode=" + user_lang_code + "&userID=" + loggedInData.UserID;
// } else if (userSettings !== null && userSettings !== undefined && userSettings !== '') {
//     user_lang_code = userSettings.LanguageCode;
//     customerApiUrl = parts + "/api/encustomers?name=" + sub + "&languagecode=" + lang_code + "&userLanguageCode=" + user_lang_code;
// } else {
//     customerApiUrl = parts + "/api/encustomers?name=" + sub + "&languagecode=" + lang_code;
// }
// $.ajax({
//     url: customerApiUrl,
//     global: false,
//     type: 'GET',
//     data: {},
//     async: false,
//     success: function(result) {
//         if (result.isWebShopActive) {
//             sessionStorage.setItem("webshopDetail", JSON.stringify(result));
//             sessionStorage.setItem("themecolors", JSON.stringify(result.Themes[0]));
//             less.modifyVars({ '@color-base': result.Themes[0].Main, '@label-color': result.Themes[0].Lable, '@alert-color': result.Themes[0].Alert });
//         }
//     }
// });


$(document).ready(function() {

    $('#joinImg').each(function() {
        var $img = jQuery(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');
        jQuery.get(imgURL, function(data) {
            var $svg = jQuery(data).find('svg');
            if (typeof imgID !== 'undefined') {
                $svg = $svg.attr('id', imgID);
            }
            if (typeof imgClass !== 'undefined') {
                $svg = $svg.attr('class', imgClass + ' replaced-svg');
            }
            $svg = $svg.removeAttr('xmlns:a');
            $img.replaceWith($svg);
        }, 'xml');
    });

    $('body').on("mouseover", "#catlistShow, #joincustomImg", function() {

        var popupDetailpage = document.getElementById('popupDetailpage')
        if (popupDetailpage !== null) {
            document.getElementById('popupDetailpage').style.zIndex = '2';
        }
    });
    $('body').on("mouseover", ".orderMenu", function() {
        var popupDetailpage = document.getElementById('popupDetailpage')
        if (popupDetailpage !== null) {
            document.getElementById('popupDetailpage').style.zIndex = '2';
        }
    });
});

$(document).ready(function() {
    $('body').on("click", ".showZindex", function() {
        var popupDetailpage = document.getElementById('popupDetailpage')
        if (popupDetailpage !== null) {
            document.getElementById('popupDetailpage').style.zIndex = '9';
        }
    });

    $('body').on("click", ".hideZindex, .shopping_cart_icon, .currency, .language_menu ", function() {
        var popupDetailpage = document.getElementById('popupDetailpage')
        if (popupDetailpage !== null) {
            document.getElementById('popupDetailpage').style.zIndex = '2';
        }
    });

});
